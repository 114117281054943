import { TGiftItem } from "../../types";

/**
 * Creates an empty gift item object with specified reference price and POS entity ID.
 * @param referencePrice - The price value for the item in reference POS
 * @param posEntityId - The POS entity identifier
 * @returns {TGiftItem} An empty gift item object with default values:
 * - referencePrice: price in reference POS
 * - initial_price: original price of the item (default 0)
 */
export const createEmptyGiftItem = (
  referencePrice: number,
  posEntityId: string
): TGiftItem => ({
  id: "",
  name: "",
  price: referencePrice, // Price in reference POS
  initial_price: 0,
  order: 0,
  pos_entity_id: posEntityId,
  category_id: "",
  integration_id: "",
  description: "",
  image: "",
  created_at: 0,
  updated_at: 0,
});
