import { call, put, select, takeLatest } from "redux-saga/effects";
import { getLoyaltyMenuAction } from "../../../constants";
import { selectToken } from "../../selectors";
import { getStoreItems } from "../../../axios/getStoreItems";
import {
  getLoyaltyMenu,
  getLoyaltyMenuFailure,
  getLoyaltyMenuSuccess,
} from "../../actions";
import { union } from "ts-action";
import { IRootReducerState } from "../../rootReducer";
import { TLoyaltyCategory } from "../../../types";

const actionTypes = union({
  getLoyaltyMenu,
});
const selectMultiPosGiftList = (state: IRootReducerState) =>
  state.multiPosGiftListReducer;

function* getLoyaltyMenuSaga({ payload }: typeof actionTypes) {
  try {
    const multiPosGiftList = yield select(selectMultiPosGiftList);
    const selectedToken = yield select(selectToken);
    const storeItemsRes = yield call(getStoreItems, selectedToken, payload);
    const menu = storeItemsRes.data as TLoyaltyCategory[];
    yield put(
      getLoyaltyMenuSuccess({
        menu: menu.filter((item) => item.items.length),
        ...(payload
          ? {
              gift_list: payload
                ? multiPosGiftList.shownGiftListByPosId[payload].gift_list
                : undefined,
              gift_list_by_pos_id: multiPosGiftList.shownGiftListByPosId,
            }
          : {}),
      })
    );
  } catch (e) {
    yield put(getLoyaltyMenuFailure(e as Error));
  }
}

export function* watchGetLoyaltyMenuSaga() {
  yield takeLatest(getLoyaltyMenuAction.requested, getLoyaltyMenuSaga);
}
