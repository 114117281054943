import { MultiPosGiftList, TLoyaltyCategory } from "../../types";

/**
 * Filters categories based on search term, selected category, and existing gift lists.
 *
 * @param categoriesById - Object mapping category IDs to their corresponding loyalty category data
 * @param searchTerm - String to filter items by name
 * @param selectedCategoryId - ID of the selected category to filter by
 * @param shownGiftListByPosId - Object mapping POS IDs to their gift lists
 *
 * @returns Filtered object containing only matching categories and their filtered items
 *
 * @remarks
 * The function filters items based on three conditions:
 * 1. Item name matches the search term (if provided)
 * 2. Item is not already in a gift list
 * 3. Item is either not in loyalty program or there are shown gift lists
 *
 * If a category has no matching items after filtering, it will be excluded from the result.
 */
export const filterCategories = (
  categoriesById: Record<string, TLoyaltyCategory>,
  searchTerm: string,
  selectedCategoryId?: string,
  shownGiftListByPosId?: Record<string, MultiPosGiftList>
): Record<string, TLoyaltyCategory> => {
  const giftListItemIds = new Set<string>(
    Object.values(shownGiftListByPosId ?? {})
      .flatMap((pos) => pos.gift_list)
      .map((item) => item.id)
  );

  return Object.entries(categoriesById).reduce<
    Record<string, TLoyaltyCategory>
  >((acc, [key, category]) => {
    if (selectedCategoryId && key !== selectedCategoryId) {
      return acc;
    }

    const filteredItems = category.items.filter((item) => {
      const matchesSearch =
        !searchTerm || item.name.toLowerCase().includes(searchTerm);
      const notInGiftList = !giftListItemIds.has(item.id);
      const hasShownGiftList =
        Object.keys(shownGiftListByPosId ?? {}).length > 0;

      return (
        matchesSearch &&
        notInGiftList &&
        (hasShownGiftList || !item.is_in_loyalty_program)
      );
    });

    if (filteredItems.length > 0) {
      acc[key] = {
        ...category,
        items: filteredItems,
      };
    }

    return acc;
  }, {});
};
