export const storeProfile = "/api/manager/store-profile";
export const getWebPortalHistoryURL = "/api/manager/orders";
export const getWebPortalStatisticsURL =
  "/api/manager/web-portal/orders/statistics";
export const store = "api/manager/store";
export const login = "/api/manager/login";
export const logout = "/api/manager/logout";
export const getCountries = "/api/getCountries";
export const leaderBoards = "/api/leaderboards";
export const confirmPassword = "/api/manager/verify-password";
export const editLeaderBoards = (id: string) => `/api/leaderboards/${id}`;
export const getLeaderBoards = (id: string) => `/api/leaderboards/${id}`;
export const deleteLeaderboard = (id: string) => `/api/leaderboards/${id}`;
export const hasLeaderboard = `/api/nextleaderboard`;
export const getUsers = (id) => `${leaderBoards}/${id}/users`;
export const getUserProfile = "/api/manager/customer/get-data";
export const getEmojies = "/api/manager/reviews/rates-number";
export const getReviews = "/api/manager/analytics/reviews";
export const editReview = `/api/manager/reviews/update`;
export const getReviewByID = (id: string) => `/api/manager/issues/${id}`;
export const getNextReviewID = (id: string) => `/api/manager/issues/next/${id}`;
export const getPreviousReviewID = (id: string) =>
  `/api/manager/issues/previous/${id}`;
export const getReviewsNavigation = (id: string) =>
  `/api/manager/issues/navigation/${id}`;
export const getNotesByReviewID = `/api/manager/reviews/notes`;
export const getCriticalNumbers =
  "/api/manager/analytics/reviews/critical-count";
export const branches = "api/manager/branch";
export const brancheByID = (id) => `api/manager/branch/${id}`;
export const callCenter = "/api/manager/branch/call-center";
export const overallRating = "api/manager/reviews/overall";
export const totalReviewsNumber = "api/manager/reviews/total-number";
export const reviewCategories = "/api/manager/reviews/categories-tags";
export const categoriesTagsCount = "/api/manager/reviews/categories-tags-count";
export const categories = "/api/manager/categories";
export const storeItems = "/api/manager/items";
export const categoryByID = (id: string) => `/api/manager/categories/${id}`;
export const storeItem = (id: string) => `/api/manager/items/${id}`;
export const importFromElmenus = "/api/manager/import-menu/elmenus";
export const importFromHunger = "/api/manager/import-menu/hunger";
export const uploadViaExcel = "api/manager/import-menu/CSV";
export const importMenuStatus = "/api/manager/import-menu/status";
export const specialItems = "api/manager/special-items";
export const specialItemsByID = (id: string) =>
  `api/manager/special-items/${id}`;
export const convertingRatio = "api/manager/store/converting-ratio";
export const nextGift = "api/manager/customer/next-gift";
export const compensations = "api/manager/gift-inbox/gift-points/compensations";
export const cashiers = "api/manager/cashiers";
export const callCenterCashier = "/api/manager/cashiers/call-center";
export const searchCashiers = "api/manager/cashiers/search-cashier";
export const storeUsers = "api/manager/users";
export const updateStoreUserPOCAssignation =
  "api/manager/users/poc/update-assignation";
export const blockedUsers = "api/manager/blocked-users";
export const unblockUser = "api/manager/blocked-users";
export const resendEmail = "api/manager/users/resend-email";
export const userProfile = "api/manager/user-profile";
export const userTypes = "api/manager/store/alerts";
export const editMissingRoles = "api/manager/store/alerts/toggle";
export const getCheckSignup = "api/manager/users/check-signup";
export const changePassword = "api/manager/user-profile/change_password";
export const leaderboardVisitsStatistics = (id: string) =>
  `api/leaderboards/${id}/statistics/visits`;
export const addMenuItemCompensation = "api/manager/gift-inbox/menu-item";
export const addVoucherCompensation = "api/manager/gift-inbox/voucher";
export const getLatestCompensations = "api/manager/gift-inbox/latest-gifts";
export const getActivityLog = "api/manager/reviews/issue-activity";
export const getCustomerCompensationsCount =
  "api/manager/gift-inbox/statistics/customer-review-gifts";
export const getGovernments = "api/manager/government";
export const getStoreBranchesForItemVisibility = (storeId: string) =>
  `api/manager/branches/store-branches/${storeId}`;
// export const reOrderItem = ''
export const uploadMenuCSV = "";
export const verifyMenuCSV = "";
export const uplaodCutomerPointsCSV = "/api/manager/add-bulk-points";
// update menu sync availability
export const updateMenuSyncAvailabilityURL =
  "/api/manager/pos/update-menu-sync-availability";

// points operation
export const editPointsOperationURL = "/api/manager/edit-points-operation";
export const deletePointsOperationURL = "/api/manager/delete-points-operation";
export const storeViralitySettingsURL = "/api/manager/virality/store-settings";
export const recommendedGiftURL =
  "/api/manager/loyalty-program-items/first-item";

export const welcomeGiftGuaranteeURL = "/api/manager/store";

// fraud center
// get all fraudlent customer
export const getFraudlentCustomes =
  "/api/manager/analytics/store/fraudlent-customers";
export const blockFraudlentCustomer = (phone_number: string) =>
  `/api/manager/analytics/store/fraudlent-customers/${phone_number}/block`;
export const reviewFraudlentCustomer = (phone_number: string) =>
  `/api/manager/analytics/store/fraudlent-customers/${phone_number}/toggle-review`;
export const removeFraudlentCustomerPoints = (phone_number: string) =>
  `/api/manager/analytics/store/fraudlent-customers/${phone_number}/remove-points`;

// pos urls
export const uninstallPOSURL = (pos_integration: string) =>
  `/api/manager/${pos_integration}/uninstall`;

export const bannerCardsURL = "/api/manager/store/banner-cards";
export const posEntitiesURL = "/api/manager/pos-entities";

export const getPromotionsURL = "/api/admin/promo-code/get-promotions";
export const getVouchersURL = "/api/manager/vouchers";

export const multiPosGiftListURL = "/api/manager/multi-pos-gift-list";
