export default {
  title: "Add new Item",
  imagePlaceholder: "Upload item image",
  namePlaceholder: "Item Name",
  categoryPlaceholder: "Item Category",
  pricePlaceholder: "Item Price",
  integrationIdPlaceholder: "Integration Id",
  addItem: "Add Item",
  uploadImageButtonText: "Upload Image",
  uploadImageDescription: "Image should be at least 4KB and at most 1MB",
  unsupportedImageType: "Unsupported file type...",
  voucherCapping: "Voucher Capping",
  thisIsVoucher: "This is a voucher",
  selectVoucher: "Select Voucher",
  addVoucher: "Add Voucher",
  selectVoucherDescription:
    "Minimum order {{min_threshold}} {{currency}}, Max discount {{max_discount_value}} {{currency}}",
  selectVoucherError: "You can't add a voucher without capping value",
  onlyVouchersCanBeAdded: "Only vouchers can be added",
};
