import { on, reducer } from "ts-action";
import { withLoadingReducer } from "../../../Components/Pickup/redux-store/reducers/withLoadingState";
import { getLoyaltyMenuAction } from "../../../constants";
import * as actions from "../../actions";
import { mapById } from "../../../hooks/use-cashiers";
import {
  LoyaltyMenuState,
  TLoyaltyCategory,
  TLoyaltyItem,
} from "../../../types";
import { updateShownCategories } from "../../../helpers/menu/updateShownCategories";
import { filterCategories } from "../../../helpers/menu/filterCategories";
import { convertToIds } from "../../../helpers/convertToIds";

const updateItemsInCategory = (category, itemId, loading) => {
  return {
    ...category,
    items: category.items.map((item) =>
      item.id === itemId ? { ...item, loading } : item
    ),
  };
};

const initialState: LoyaltyMenuState = {
  categoriesIds: [],
  shownCategoriesIds: [],
  shownCategoriesById: {},
  categoriesById: {},
  selectedCategoryId: "",
  search: "",
};
export default withLoadingReducer<LoyaltyMenuState>(
  reducer<LoyaltyMenuState>(
    [
      on(actions.getLoyaltyMenuFailure, (state) => ({
        ...state,
        categoriesIds: [],
        shownCategoriesIds: [],
        categoriesById: {},
        shownCategoriesById: {},
      })),

      on(actions.getLoyaltyMenuSuccess, (state, { payload }) => {
        const multiPosGiftsIds = convertToIds(payload.gift_list || [])();
        const isPosEnabled = multiPosGiftsIds.size > 0;

        const { categories, shownCategories } = payload.menu.reduce<{
          categories: TLoyaltyCategory[];
          shownCategories: TLoyaltyCategory[];
        }>(
          (acc, category) => {
            const items = category.items.map((item) => ({
              ...item,
              category_id: category.id,
            }));

            const itemsNotInLoyalty = items.filter((item) =>
              isPosEnabled
                ? !multiPosGiftsIds?.has(item.id)
                : !item.is_in_loyalty_program
            );

            acc.categories.push({ ...category, items });

            if (itemsNotInLoyalty.length > 0) {
              acc.shownCategories.push({
                ...category,
                items: itemsNotInLoyalty,
              });
            }

            return acc;
          },
          {
            categories: [],
            shownCategories: [],
          }
        );
        return {
          ...state,
          categoriesIds: categories.map((item) => item.id),
          shownCategoriesIds: shownCategories.map((item) => item.id),
          categoriesById: mapById(categories),
          shownCategoriesById: mapById(shownCategories),
        };
      }),

      on(actions.searchLoyaltyMenuItems, (state, { payload }) => {
        const searchTerm = payload.search_term.toLowerCase();
        const filteredCategories = filterCategories(
          state.categoriesById,
          searchTerm,
          state.selectedCategoryId,
          payload.gift_list_by_pos_id
        );

        return {
          ...state,
          search: payload.search_term,
          shownCategoriesById: filteredCategories,
          shownCategoriesIds: Object.keys(filteredCategories),
        };
      }),

      on(actions.filterLoyaltyMenuItemsByCategory, (state, { payload }) => {
        const searchTerm = state.search.toLowerCase();
        const filteredCategories = filterCategories(
          state.categoriesById,
          searchTerm,
          payload.category_id,
          payload?.gift_list_by_pos_id
        );

        return {
          ...state,
          selectedCategoryId: payload.category_id,
          shownCategoriesById: filteredCategories,
          shownCategoriesIds: Object.keys(filteredCategories),
        };
      }),

      on(actions.editLoyaltyMenuItemSuccess, (state, { payload }) => {
        const categoryId = payload.category_id;
        const isRemovingFromLoyalty = !payload.is_in_loyalty_program;

        const updatedCategories: Record<string, TLoyaltyCategory> = {};
        const updatedShownCategories: Record<string, TLoyaltyCategory> = {};

        for (const [key, category] of Object.entries(state.categoriesById)) {
          const items = category.items.map((item) =>
            item.id === payload.id
              ? { ...item, ...payload, loading: false }
              : item
          );

          updatedCategories[key] = {
            ...category,
            items,
          };

          const existingShownCategory = state.shownCategoriesById[key];
          if (existingShownCategory) {
            const nonLoyaltyItems = items.filter(
              (item) => !item.is_in_loyalty_program
            );
            if (nonLoyaltyItems.length > 0) {
              updatedShownCategories[key] = {
                ...category,
                items: nonLoyaltyItems,
              };
            }
          } else if (isRemovingFromLoyalty && key === categoryId) {
            const nonLoyaltyItems = items.filter(
              (item) => !item.is_in_loyalty_program
            );
            if (nonLoyaltyItems.length > 0) {
              updatedShownCategories[key] = {
                ...category,
                items: nonLoyaltyItems,
              };
            }
          }
        }

        if (Object.keys(updatedShownCategories).length === 0) {
          for (const [key, category] of Object.entries(updatedCategories)) {
            const nonLoyaltyItems = category.items.filter(
              (item) => !item.is_in_loyalty_program
            );
            if (nonLoyaltyItems.length > 0) {
              updatedShownCategories[key] = {
                ...category,
                items: nonLoyaltyItems,
              };
            }
          }
        }
        return {
          ...state,
          shownCategoriesIds: Object.keys(updatedShownCategories),
          shownCategoriesById: updatedShownCategories,
          categoriesById: updatedCategories,
        };
      }),

      on(actions.removePosGiftItemAction, (state, { payload }) => {
        const updatedShownCategories =
          payload.is_voucher || !payload.category_id
            ? state.shownCategoriesById
            : updateShownCategories(
                state.categoriesById,
                state.shownCategoriesById,
                payload,
                true,
                state.search,
                state.selectedCategoryId
              );
        return {
          ...state,
          shownCategoriesById: updatedShownCategories,
          shownCategoriesIds:
            payload.is_voucher || !payload.category_id
              ? state.shownCategoriesIds
              : Object.keys(updatedShownCategories),
        };
      }),

      on(actions.addPosGiftItemAction, (state, { payload }) => {
        let updatedShownCategories = state.shownCategoriesById;
        if (payload.updated) {
          updatedShownCategories = updateShownCategories(
            state.categoriesById,
            state.shownCategoriesById,
            payload.item,
            false,
            state.search,
            state.selectedCategoryId
          );
        }
        return {
          ...state,
          shownCategoriesById: updatedShownCategories,
          shownCategoriesIds: Object.keys(updatedShownCategories),
        };
      }),

      on(actions.selectPosGiftList, (state, { payload }) => {
        const isSamePos =
          payload.current.pos_entity_id === payload.prev.pos_entity_id;

        return {
          ...state,
          search: isSamePos ? state.search : "",
          selectedCategoryId: isSamePos ? state.selectedCategoryId : "",
        };
      }),
      on(actions.editLoyaltyMenuItemFailure, (state, { payload }) => ({
        ...state,
        shownCategoriesById: mapById(
          state.shownCategoriesIds.map((id) => {
            const category = state.shownCategoriesById[id];
            return updateItemsInCategory(category, payload.id, false);
          })
        ),
        categoriesById: mapById(
          state.categoriesIds.map((id) => {
            const category = state.categoriesById[id];
            return updateItemsInCategory(category, payload.id, false);
          })
        ),
      })),
      on(actions.editLoyaltyMenuItem, (state, { payload }) => ({
        ...state,
        shownCategoriesById: mapById(
          state.shownCategoriesIds.map((id) => {
            const category = state.shownCategoriesById[id];
            return updateItemsInCategory(category, payload.id, true);
          })
        ),
        categoriesById: mapById(
          state.categoriesIds.map((id) => {
            const category = state.categoriesById[id];
            return updateItemsInCategory(category, payload.id, true);
          })
        ),
      })),
    ],
    initialState
  ),
  getLoyaltyMenuAction
);
