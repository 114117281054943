import * as constants from "../../constants/actions";
import { ICreateCategory } from "../../../../types";
import { action, payload } from "ts-action";
import { IOrderingMenuCategory } from "../../lib/types";
import { optAction, optReaction } from "./lib";
import { COMMIT, REVERT } from "redux-optimistic-ui";

export const createOrderingCategory = optAction(
  constants.createOrderingCategoryAction.requested,
  payload<ICreateCategory>()
);
export const createOrderingCategorySuccess = optReaction(
  constants.createOrderingCategoryAction.fulfilled,
  payload<IOrderingMenuCategory>(),
  COMMIT
);
export const createOrderingCategoryFailure = optReaction(
  constants.createOrderingCategoryAction.rejected,
  payload<Error>(),
  REVERT
);

export const getOrderingCategories = action(
  constants.getOrderingCategoriesAction.requested,
  payload<{ menu_id: string, branch_id?: string; }>()
);
export const getOrderingCategoriesSuccess = action(
  constants.getOrderingCategoriesAction.fulfilled,
  payload<IOrderingMenuCategory[]>()
);
export const getOrderingCategoriesFailure = action(
  constants.getOrderingCategoriesAction.rejected,
  payload<Error>()
);

export const reOrderCategory = optAction(
  constants.reOrderCategoryAction.requested,
  payload<IOrderingMenuCategory>()
);
export const reOrderCategorySuccess = optReaction(
  constants.reOrderCategoryAction.fulfilled,
  payload<IOrderingMenuCategory>(),
  COMMIT
);
export const reOrderCategoryFailure = optReaction(
  constants.reOrderCategoryAction.rejected,
  payload<IOrderingMenuCategory>(),
  REVERT
);

export const editOrderingCategory = optAction(
  constants.editOrderingCategories.requested,
  payload<IOrderingMenuCategory>()
);

export const editOrderingCategorySuccess = optReaction(
  constants.editOrderingCategories.fulfilled,
  payload<IOrderingMenuCategory>(),
  COMMIT
);

export const editOrderingCategoryFailure = optReaction(
  constants.editOrderingCategories.rejected,
  payload<Error>(),
  REVERT
);

export const deleteOrderingCategory = optAction(
  constants.deleteOrderingMenuCategoryAction.requested,
  payload<string>()
);
export const deleteOrderingCategorySuccess = optReaction(
  constants.deleteOrderingMenuCategoryAction.fulfilled,
  payload<IOrderingMenuCategory>(),
  COMMIT
);
export const deleteOrderingCategoryFailure = optReaction(
  constants.deleteOrderingMenuCategoryAction.rejected,
  payload<Error>(),
  REVERT
);
