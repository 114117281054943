import {
  GiftOperationResult,
  MultiPosGiftListState,
  TGiftItem,
} from "../../types";
import { createEmptyGiftItem } from "./createEmptyGiftItem";
import { reorderGiftList } from "./reorderGiftList";

/**
 * Removes a gift item across multiple POS entities while maintaining consistency
 *
 * @param state - Current state containing gift lists for multiple POS entities
 * @param posEntityId - Target POS entity ID where the item should be removed
 * @param item - Gift item to be removed
 *
 * @returns An object containing the updated state and a boolean indicating if the operation was successful
 *
 * @remarks
 * - When removing from reference POS: Removes matching items from all POS lists
 * - When removing from non-reference POS: Replaces item with placeholder or removes it
 * - Maintains order consistency across all POS entities
 */
export const removeGiftItemAcrossPos = (
  state: MultiPosGiftListState,
  posEntityId: string,
  item: TGiftItem
): GiftOperationResult => {
  const referencePosId = Object.keys(state.shownGiftListByPosId)[0];
  const referenceGiftList =
    state.shownGiftListByPosId[referencePosId].gift_list;

  if (posEntityId === referencePosId) {
    const removedItem = state.shownGiftListByPosId[
      referencePosId
    ].gift_list.find((gift) => gift.id === item.id);

    if (!removedItem) {
      return { ...state, updated: false };
    }

    const itemIndex =
      state.shownGiftListByPosId[referencePosId].gift_list.indexOf(removedItem);

    const updatedPosLists = new Map();

    for (const [key, value] of Object.entries(state.shownGiftListByPosId)) {
      const updatedGiftList = [...value.gift_list];
      if (itemIndex !== -1) {
        updatedGiftList.splice(itemIndex, 1);
      }
      updatedPosLists.set(key, {
        ...value,
        gift_list: reorderGiftList(updatedGiftList),
      });
    }

    return {
      ...state,
      shownGiftListByPosId: Object.fromEntries(updatedPosLists),
      updated: true,
    };
  } else {
    const targetGiftList = state.shownGiftListByPosId[posEntityId].gift_list;

    const referenceOrders = new Set(
      referenceGiftList
        .filter((gift) => gift.price === item.price)
        .map((gift) => gift.order)
    );

    if (referenceOrders.has(item.order)) {
      const placeholder = {
        ...createEmptyGiftItem(item.price, posEntityId),
      };

      const updatedGiftList = targetGiftList.map((gift) =>
        gift.id === item.id ? placeholder : gift
      );

      return {
        ...state,
        shownGiftListByPosId: {
          ...state.shownGiftListByPosId,
          [posEntityId]: {
            ...state.shownGiftListByPosId[posEntityId],
            gift_list: reorderGiftList(updatedGiftList),
          },
        },
        updated: true,
      };
    }

    return {
      ...state,
      shownGiftListByPosId: {
        ...state.shownGiftListByPosId,
        [posEntityId]: {
          ...state.shownGiftListByPosId[posEntityId],
          gift_list: reorderGiftList(
            targetGiftList.filter((gift) => gift.id !== item.id)
          ),
        },
      },
      updated: true,
    };
  }
};
