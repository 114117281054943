import menu from "./menu";
export default {
  addFromMenu: "Add from menu",
  customGift: "Add custom gift",
  giftName: "Gift Name",
  price: "Price",
  giftList: "Gift List",
  atLeast: "(Add at least 7 items)",
  giftItems: "Gift Items",
  progressAutoSaved: menu.progressAutoSaved,
  markComplete: menu.markComplete,
  addSpecialItemSuccess: '"{{name}}" added successfully to your gift list',
  addSpecialItemFailure: 'Failed to add "{{name}}" to your gift list',
  itemHasNoImageError:
    "Any item that is added to the gift list must have an image, please edit this item in the Menu interface",
  failedToAddItems: "Server or Connection Error, please, try again later.",
  areYouSureYouWantToDeleteThisItem:
    "Are you sure you want to remove this gift?",
  deleteGiftItemDescription:
    "By removing this gift from “{{pos_name}}”, all similar gifts across other POSs will also be removed. Are you sure you want to proceed?",
  cancel: "Cancel",
  delete: "Delete",
  giftMapIncompatible:
    "The gift list for “{{pos_name}}” is incompatible with the other gift lists. Please adjust it to align with the “{{reference_pos_name}}” gift list.",
  duplicateItemPrice:
    "This price is already assigned to another gift in “{{pos_name}}”. Please remove the existing gift first or add another gift with the same price in “{{reference_pos_name}}” before proceeding.",
  saveChanges: "Save Changes",
  loyaltyMapUpdated: "The loyalty map has been updated",
  itemPriceIncompatible: "The item's price is incompatible",
};
