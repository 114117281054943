import { orderBy } from "lodash";
import { MultiPosGiftList, TGiftItem } from "../../types";
import { createEmptyGiftItem } from "./createEmptyGiftItem";

/**
 * Maps POS gift lists to a reference-based structure, organizing gift items by price order.
 *
 * @param payload - Array of POS entities containing gift lists
 * @param payload[].pos_entity_id - Unique identifier for the POS entity
 * @param payload[].pos_entity_name - Name of the POS entity
 * @param payload[].gift_list - Array of gift items associated with the POS entity
 *
 * @returns An object mapping POS entity IDs to their corresponding structured gift lists
 * Each mapped gift list maintains consistent ordering based on the reference POS's price structure
 * and includes placeholder items where price matches are missing
 *
 * @remarks
 * - The first POS entity in the payload is used as the reference for price ordering
 * - Gift items are ordered by ascending price
 * - Non-reference POS entities will have their gift lists aligned with the reference pricing structure
 * - Missing price points are filled with empty gift items to maintain consistent structure
 */
export const mapPosGiftListsToReference = (
  payload: Array<{
    pos_entity_id: string;
    pos_entity_name: string;
    gift_list: TGiftItem[];
  }>
): Record<string, MultiPosGiftList> => {
  const referencePosId = payload[0]?.pos_entity_id;
  if (!referencePosId) return {};

  const referenceGiftList =
    payload.find((pos) => pos.pos_entity_id === referencePosId)?.gift_list ||
    [];

  const orderedReferenceGiftList = orderBy(
    referenceGiftList,
    ["price"],
    ["asc"]
  ).map((item, index) => ({
    ...item,
    order: index,
    initial_price: item.price,
  }));

  return payload.reduce<Record<string, MultiPosGiftList>>(
    (acc, { pos_entity_id, pos_entity_name, gift_list }) => {
      const isReference = pos_entity_id === referencePosId;
      let transformedGiftList;

      if (isReference) {
        transformedGiftList = orderedReferenceGiftList;
      } else {
        const matchingItems = gift_list
          .map((item, index) => ({
            ...item,
            order: index,
            initial_price: item.price,
          }))
          .filter((item) => item.order !== -1);

        const placeholders = orderedReferenceGiftList
          .filter(
            (refItem) => !gift_list.some((item) => item.price === refItem.price)
          )
          .map((refItem) => ({
            ...createEmptyGiftItem(refItem.price, pos_entity_id),
            order: refItem.order,
          }));

        transformedGiftList = [...matchingItems, ...placeholders].sort(
          (a, b) => a.order - b.order
        );
      }

      return {
        ...acc,
        [pos_entity_id]: {
          pos_entity_id,
          pos_entity_name,
          gift_list: transformedGiftList,
        },
      };
    },
    {}
  );
};
