import * as Requests from "./requests";
import axios from "./axios";
import { IOrderingMenuCategory } from "../lib/types";

export const getCategories = (token: string, menu_id: string, branch_id?: string) => {
  return axios.get<{ categories: IOrderingMenuCategory[] }>(
    Requests.ORDERING_MENU_CATEGORIES_API,
    {
      params: {
        menu_id,
        branch_id,
      },
      headers: {
        token,
      },
    }
  );
};
