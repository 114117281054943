import { MultiPosGiftList, MultiPosGiftListPayload } from "../../types";
import axios from "../axios";
import { multiPosGiftListURL } from "../requests";
import { AxiosReturn } from "../types";

export const editMultiPosGiftListApi = (
  token: string,
  data: MultiPosGiftListPayload
): AxiosReturn<MultiPosGiftList[]> => {
  return axios.post(multiPosGiftListURL, data, {
    headers: {
      token,
    },
  });
};
