import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  getLoyaltyMenu,
  getMultiPosGiftList,
  getMultiPosGiftListFailure,
  getPosEntitiesFailure,
  getPosEntitiesSuccess,
} from "../../actions";
import { selectToken } from "../../selectors";
import { getPosEntitiesApi } from "../../../axios/pos";
import { getPosEntitiesAction } from "../../../constants";

function* getPosEntitiesSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield call(getPosEntitiesApi, token);
    if (res.data.pos.length > 1) {
      yield put(getMultiPosGiftList());
    } else {
      yield put(getMultiPosGiftListFailure(undefined));
    }
    yield put(getPosEntitiesSuccess(res.data.pos));
  } catch (error) {
    yield put(getPosEntitiesFailure(error as Error));
    yield put(getMultiPosGiftListFailure(undefined));
  }
}

export function* watchGetPosEntitiesSaga() {
  yield takeLatest(getPosEntitiesAction.requested, getPosEntitiesSaga);
}
