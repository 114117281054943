import { useEffect, useState } from "react";
import { useSelect } from "./useSelect";
import { PosVendor } from "../types";

export const usePOS = (isEnabled: boolean) => {
    const [hasPOS, setHasPOS] = useState(false);
    const { entitiesByVendor } = useSelect((state) => state.getPosEntitiesReducer)
    useEffect(() => {
        if (!entitiesByVendor) {
            setHasPOS(false);
            return;
        }
        for (const vendor in PosVendor) {
            if (vendor in entitiesByVendor) {
                setHasPOS(true);
                return;
            }
        }
    }, [entitiesByVendor])
    return {
        hasPOS: isEnabled && hasPOS,
    }
};
