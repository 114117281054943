import { memoize } from "lodash";
import {
  GiftListPayload,
  MultiPosGiftList,
  MultiPosGiftListPayload,
  TGiftItem,
} from "../../types";

export const mapGiftItemToPayload = memoize(
  (
    shownGiftListByPosId: Record<string, MultiPosGiftList>
  ): MultiPosGiftListPayload => {
    return {
      gift_lists: Object.values(shownGiftListByPosId).map((posList) => {
        if (!posList?.gift_list?.length) {
          return [];
        }
        return posList.gift_list.map((item: TGiftItem): GiftListPayload => {
          if (typeof item.is_voucher !== "boolean" || !item.is_voucher) {
            return {
              id: item.id,
              price: item.price,
            };
          }

          if (item.id) {
            return {
              id: item.id,
              is_voucher: true,
              price: item.price,
            };
          }

          return {
            is_voucher: true,
            price: item.price,
            name: item.name,
            image: item.image,
            promo_code: item.promo_code,
            promo_id: item.promo_id,
          };
        });
      }),
    };
  },
  (shownGiftListByPosId) => JSON.stringify(shownGiftListByPosId)
);
