import { action, payload } from "ts-action";
import {
  ACTION_ADD_LOYALTY_SPECIAL_ITEM_ACROSS_POS,
  ACTION_ADD_POS_GIFT_ITEM,
  ACTION_REMOVE_POS_GIFT_ITEM,
  ACTION_SELECT_POS_GIFT_ITEM,
  ACTION_UPDATE_LOYALTY_MENU_CATEGORY_ITEM,
  editMultiPosGiftListAction,
  getMultiPosGiftListAction,
} from "../../../constants";
import {
  MultiPosGiftList,
  TGiftItem,
  MultiPosGiftListState,
} from "../../../types";
import { IAddSpecialItemData } from "../../../types/wizard-types";

export const getMultiPosGiftList = action(getMultiPosGiftListAction.requested);
export const getMultiPosGiftListSuccess = action(
  getMultiPosGiftListAction.fulfilled,
  payload<MultiPosGiftList[]>()
);
export const getMultiPosGiftListFailure = action(
  getMultiPosGiftListAction.rejected,
  payload<Error | undefined>()
);

export const editMultiPosGiftList = action(
  editMultiPosGiftListAction.requested
);
export const editMultiPosGiftListSuccess = action(
  editMultiPosGiftListAction.fulfilled,
  payload<any>()
);
export const editMultiPosGiftListFailure = action(
  editMultiPosGiftListAction.rejected,
  payload<Error>()
);

export const addPosGiftItemAction = action(
  ACTION_ADD_POS_GIFT_ITEM,
  payload<{
    updatedState: MultiPosGiftListState;
    updated: boolean;
    item: TGiftItem;
  }>()
);

export const removePosGiftItemAction = action(
  ACTION_REMOVE_POS_GIFT_ITEM,
  payload<TGiftItem>()
);

export const selectPosGiftList = action(
  ACTION_SELECT_POS_GIFT_ITEM,
  payload<{
    prev: Partial<MultiPosGiftList>;
    current: MultiPosGiftList;
  }>()
);

export const addLoyaltyItemToPosGiftLists = action(
  ACTION_ADD_LOYALTY_SPECIAL_ITEM_ACROSS_POS,
  payload<IAddSpecialItemData>()
);

export const updateLoyaltyMenuCategoryItem = action(
  ACTION_UPDATE_LOYALTY_MENU_CATEGORY_ITEM
);
