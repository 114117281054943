import { call, put, select, takeLatest } from "redux-saga/effects";
import { getCategories as getCategoryItems } from "../../axios/getCategories";
import { selectToken } from "../../../../redux-store/selectors";
import { getOrderingCategoriesAction } from "../../constants/actions";
import {
  getOrderingCategoriesSuccess,
  getOrderingCategoriesFailure,
} from "../actions/categoryActions";

function* getCategoriesSaga(action) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getCategoryItems, token, action.payload.menu_id, action.payload.branch_id);
    yield put(
      getOrderingCategoriesSuccess((res.data || { categories: [] }).categories)
    );
  } catch (e) {
    yield put(getOrderingCategoriesFailure(e));
  }
}

export function* watchGetCategoriesSaga() {
  yield takeLatest(getOrderingCategoriesAction.requested, getCategoriesSaga);
}
