import { call, put, select, takeLatest } from "redux-saga/effects";
import { getMultiPosGiftListAction } from "../../../constants";
import {
  getLoyaltyMenu,
  getMultiPosGiftListFailure,
  getMultiPosGiftListSuccess,
  selectPosGiftList,
} from "../../actions";
import { selectToken } from "../../selectors";
import { getMultiPosGiftListApi } from "../../../axios";
import { MultiPosGiftList } from "../../../types";
import { AxiosResponse } from "axios";

function* getMultiPosGiftListSaga() {
  try {
    const token = yield select(selectToken);
    const res: AxiosResponse<MultiPosGiftList[]> = yield call(
      getMultiPosGiftListApi,
      token
    );
    yield put(getMultiPosGiftListSuccess(res.data));
  } catch (error) {
    yield put(getMultiPosGiftListFailure(error as Error));
  }
}

export default function* watchGetMultiPosGiftListSaga() {
  yield takeLatest(
    getMultiPosGiftListAction.requested,
    getMultiPosGiftListSaga
  );
}
