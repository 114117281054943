export default {
  addFromMenu: "addFromMenu",
  customGift: "customGift",
  giftName: "giftName",
  price: "price",
  atLeast: "atLeast",
  giftItems: "giftItems",
  giftList: "giftList",
  progressAutoSaved: "progressAutoSaved",
  markComplete: "markComplete",
  addSpecialItemSuccess: "addSpecialItemSuccess",
  addSpecialItemFailure: "addSpecialItemFailure",
  /**
   * @see CODE_ITEM_HAS_NO_IMAGE
   */
  itemHasNoImageError: "itemHasNoImageError",
  giftMapIncompatible: "giftMapIncompatible",
  loyaltyMapUpdated: "loyaltyMapUpdated",
  failedToAddItems: "failedToAddItems",
  areYouSureYouWantToDeleteThisItem: "areYouSureYouWantToDeleteThisItem",
  deleteGiftItemDescription: "deleteGiftItemDescription",
  cancel: "cancel",
  delete: "delete",
  duplicateItemPrice: "duplicateItemPrice",
  saveChanges: "saveChanges",
  itemPriceIncompatible: "itemPriceIncompatible",
};
