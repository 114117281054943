import { action, payload } from "ts-action";
import {
  ACTION_FILTER_LOYALTY_MENU_ITEMS_BY_CATEGORY,
  ACTION_SEARCH_LOYALTY_MENU_ITEMS,
  addLoyaltySpecialItemAction,
  deleteLoyaltySpecialItemAction,
  editLoyaltyMenuItemAction,
  editLoyaltySpecialItemAction,
  getLoyaltyMenuAction,
  getLoyaltySpecialItemsAction,
} from "../../../constants";
import { IAddSpecialItemData } from "../../../types/wizard-types";
import {
  MultiPosGiftList,
  TGiftItem,
  TLoyaltyCategory,
  TLoyaltyItem,
} from "../../../types";

export type EditLoyaltyMenuItemPayload = {
  id: string;
  is_in_loyalty_program?: number;
  name?: string;
  price?: number;
  image?: string;
  integration_id?: string;
  category_id: string;
};
export const getLoyaltyMenu = action(
  getLoyaltyMenuAction.requested,
  payload<string | undefined>()
);
export const getLoyaltyMenuSuccess = action(
  getLoyaltyMenuAction.fulfilled,
  payload<{
    menu: TLoyaltyCategory[];
    gift_list?: Array<TGiftItem>;
    gift_list_by_pos_id?: Record<string, MultiPosGiftList>;
  }>()
);
export const getLoyaltyMenuFailure = action(
  getLoyaltyMenuAction.rejected,
  payload<Error>()
);

export const editLoyaltyMenuItem = action(
  editLoyaltyMenuItemAction.requested,
  payload<EditLoyaltyMenuItemPayload>()
);
export const editLoyaltyMenuItemSuccess = action(
  editLoyaltyMenuItemAction.fulfilled,
  payload<TLoyaltyItem>()
);

export const editLoyaltyMenuItemFailure = action(
  editLoyaltyMenuItemAction.rejected,
  payload<{ error: Error; id: string }>()
);

export const searchLoyaltyMenuItems = action(
  ACTION_SEARCH_LOYALTY_MENU_ITEMS,
  payload<{
    search_term: string;
    gift_list_by_pos_id?: Record<string, MultiPosGiftList>;
  }>()
);

export const filterLoyaltyMenuItemsByCategory = action(
  ACTION_FILTER_LOYALTY_MENU_ITEMS_BY_CATEGORY,
  payload<{
    category_id?: string;
    gift_list_by_pos_id?: Record<string, MultiPosGiftList>;
  }>()
);

export const getLoyaltySpecialItems = action(
  getLoyaltySpecialItemsAction.requested
);
export const getLoyaltySpecialItemsSuccess = action(
  getLoyaltySpecialItemsAction.fulfilled,
  payload<TLoyaltyItem[]>()
);
export const getLoyaltySpecialItemsFailure = action(
  getLoyaltySpecialItemsAction.rejected,
  payload<Error>()
);

export const deleteLoyaltySpecialItem = action(
  deleteLoyaltySpecialItemAction.requested,
  payload<string>()
);

export const deleteLoyaltySpecialItemSuccess = action(
  deleteLoyaltySpecialItemAction.fulfilled,
  payload<string>()
);

export const deleteLoyaltySpecialItemFailure = action(
  deleteLoyaltySpecialItemAction.rejected,
  payload<Error>()
);

export const editLoyaltySpecialItem = action(
  editLoyaltySpecialItemAction.requested,
  payload<EditLoyaltyMenuItemPayload & { state: number }>()
);
export const editLoyaltySpecialItemSuccess = action(
  editLoyaltySpecialItemAction.fulfilled,
  payload<EditLoyaltyMenuItemPayload & { state: number }>()
);
export const editLoyaltySpecialItemFailure = action(
  editLoyaltySpecialItemAction.rejected,
  payload<Error>()
);

export const addLoyaltySpecialItem = action(
  addLoyaltySpecialItemAction.requested,
  payload<IAddSpecialItemData>()
);

export const addLoyaltySpecialItemSuccess = action(
  addLoyaltySpecialItemAction.fulfilled,
  payload<TLoyaltyItem>()
);

export const addLoyaltySpecialItemFailure = action(
  addLoyaltySpecialItemAction.rejected,
  payload<Error>()
);
