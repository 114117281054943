import { call, put, select, takeLatest } from "redux-saga/effects";
import { editMultiPosGiftListAction } from "../../../constants";
import {
  editMultiPosGiftListFailure,
  editMultiPosGiftListSuccess,
} from "../../actions";
import { editMultiPosGiftListApi } from "../../../axios";
import { selectShownGiftListByPosId, selectToken } from "../../selectors";
import { notify } from "react-notify-toast";
import { translate } from "../../../helpers/translate";
import strings from "../../../i18n/strings/giftList";
import { mapGiftItemToPayload } from "../../../helpers/gift-list/mapGiftItemToPayload";

function* editMultiPosGiftListSaga() {
  const t = translate("giftList");
  try {
    const shownGiftListByPosId = yield select(selectShownGiftListByPosId);
    const payload = mapGiftItemToPayload(shownGiftListByPosId);
    const token = yield select(selectToken);
    const res = yield call(editMultiPosGiftListApi, token, payload);
    yield put(editMultiPosGiftListSuccess(res.data));
    notify.show(t(strings.loyaltyMapUpdated), "success");
  } catch (error) {
    yield put(editMultiPosGiftListFailure(error as Error));
    notify.show(t(strings.failedToAddItems), "error");
  }
}

export function* watchEditMultiPosGiftListSaga() {
  yield takeLatest(
    editMultiPosGiftListAction.requested,
    editMultiPosGiftListSaga
  );
}
