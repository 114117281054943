import { MultiPosGiftList } from "../../types";
import axios from "../axios";
import { multiPosGiftListURL } from "../requests";
import { AxiosReturn } from "../types";

export const getMultiPosGiftListApi = (
  token: string
): AxiosReturn<MultiPosGiftList[]> => {
  return axios.get(multiPosGiftListURL, {
    headers: {
      token,
    },
  });
};
