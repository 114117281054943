import { MultiPosGiftList } from "../../types";
import { IAddSpecialItemData } from "../../types/wizard-types";
import { reorderGiftList } from "./reorderGiftList";

/**
 * Adds a new item to each POS gift list in the map and reorders the items.
 *
 * @param giftListMap - A map of POS IDs to their corresponding gift lists
 * @param payload - The data for the new special item to be added
 * @returns A new map with the updated and reordered gift lists
 */
export const addAndReorderItemInPosGiftLists = (
  giftListMap: Record<string, MultiPosGiftList>,
  payload: IAddSpecialItemData & { voucher_image?: string | File[] }
): Record<string, MultiPosGiftList> =>
  Object.entries(giftListMap).reduce((acc, [posId, posList]) => {
    const updatedGiftList = reorderGiftList([
      ...posList.gift_list,
      {
        ...payload,
        initial_price: payload.price,
        pos_entity_id: posId,
        id: "",
        category_id: "",
      },
    ]);
    return {
      ...acc,
      [posId]: {
        ...posList,
        gift_list: updatedGiftList,
      },
    };
  }, {});
