import { watchEditMultiPosGiftListSaga } from "./editMultiPosGiftListSaga";
import watchGetMultiPosGiftListSaga from "./getMultiPosGiftListSaga";
import { watchGetPosEntitiesSaga } from "./getPosEntitiesSaga";
import { watchUpdatePosEntitySaga } from "./updatePosEntitySaga";

export default [
  watchGetPosEntitiesSaga(),
  watchUpdatePosEntitySaga(),
  watchGetMultiPosGiftListSaga(),
  watchEditMultiPosGiftListSaga(),
];
