import { LoyaltySpecialItemsState, TLoyaltyItem } from "../../types";
import * as actions from "../../redux-store/actions";
import { LoadingStatus } from "../../Components/Pickup/redux-store/reducers/withLoadingState";
import { sortBy } from "lodash";
export const updateLoyaltyMenuItem = (
  state: LoyaltySpecialItemsState,
  payload: string | TLoyaltyItem,
  actionType: string
): LoyaltySpecialItemsState => {
  const actionConfig = {
    [actions.editLoyaltyMenuItem.type]: {
      status: LoadingStatus.loading,
      loading: true,
    },
    [actions.editLoyaltyMenuItemSuccess.type]: {
      status: LoadingStatus.success,
      loading: false,
    },
    [actions.editLoyaltyMenuItemFailure.type]: {
      status: LoadingStatus.failed,
      loading: false,
    },
  };

  const config = actionConfig[actionType];

  if (!config) return state;

  if (
    typeof payload === "object" &&
    payload.is_in_loyalty_program !== undefined
  ) {
    const existingIndex = state.giftItems.findIndex(
      (item) => item.id === payload.id
    );

    const updatedGiftItems = payload.is_in_loyalty_program
      ? existingIndex !== -1
        ? state.giftItems.map((item) =>
            item.id === payload.id
              ? { ...item, ...payload, loading: config.loading }
              : item
          )
        : [...state.giftItems, { ...payload, loading: config.loading }]
      : state.giftItems.filter((item) => item.id !== payload.id);

    return {
      ...state,
      status: config.status,
      giftItems: sortBy(updatedGiftItems, ["price"]),
    };
  }

  const targetItem = state.giftItems.find((item) => item.id === payload);

  if (!targetItem) {
    return {
      ...state,
      status: config.status,
    };
  }

  const updatedItems = state.giftItems.map((item) =>
    item.id === payload ? { ...item, loading: config.loading } : item
  );

  return {
    ...state,
    giftItems: updatedItems,
    status: config.status,
  };
};
