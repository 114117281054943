import { action, payload } from "ts-action";
import {
  getPosEntitiesAction,
  updatePosEntityAction,
} from "../../../constants";
import { IPosEntity } from "../../../types";

export const getPosEntities = action(getPosEntitiesAction.requested);
export const getPosEntitiesSuccess = action(
  getPosEntitiesAction.fulfilled,
  payload<IPosEntity[]>()
);
export const getPosEntitiesFailure = action(
  getPosEntitiesAction.rejected,
  payload<Error>()
);

export const updatePosEntity = action(
  updatePosEntityAction.requested,
  payload<
    Omit<
      IPosEntity,
      "created_at" | "updated_at" | "api_key" | "store_id" | "isShared"
    >
  >()
);
export const updatePosEntitySuccess = action(
  updatePosEntityAction.fulfilled,
  payload<IPosEntity>()
);
export const updatePosEntityFailure = action(
  updatePosEntityAction.rejected,
  payload<Error>()
);

export const updatePosEntityReset = action(
  `${updatePosEntityAction.rejected}_RESET`,
  payload<Error>()
);
