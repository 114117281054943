import { on, reducer } from "ts-action";
import { withLoadingReducer } from "../../../Components/Pickup/redux-store/reducers/withLoadingState";
import * as actions from "../../actions";
import { getMultiPosGiftListAction } from "../../../constants";
import { MultiPosGiftListState } from "../../../types";
import { mapPosGiftListsToReference } from "../../../helpers/gift-list/mapPosGiftListsToReference";
import { addAndReorderItemInPosGiftLists } from "../../../helpers/gift-list/addAndReorderItemInPosGiftLists";
import { notify } from "react-notify-toast";
import { removeGiftItemAcrossPos } from "../../../helpers/gift-list/removeGiftItemAcrossPos";
import { translate } from "../../../helpers/translate";
import strings from "../../../i18n/strings/giftList";
const initialState: MultiPosGiftListState = {
  giftListByPosId: {},
  shownGiftListByPosId: {},
  selectedPosGiftList: {},
};
const t = translate("giftList");
export default withLoadingReducer<MultiPosGiftListState>(
  reducer<MultiPosGiftListState>(
    [
      on(actions.getMultiPosGiftListSuccess, (state, { payload }) => {
        const transformedGiftListByPosId = mapPosGiftListsToReference(payload);
        return {
          ...state,
          giftListByPosId: transformedGiftListByPosId,
          shownGiftListByPosId: transformedGiftListByPosId,
        };
      }),
      on(actions.addLoyaltyItemToPosGiftLists, (state, { payload }) => {
        return {
          ...state,
          shownGiftListByPosId: addAndReorderItemInPosGiftLists(
            state.shownGiftListByPosId,
            payload
          ),
        };
      }),
      on(actions.selectPosGiftList, (state, { payload }) => {
        return {
          ...state,
          selectedPosGiftList: payload.current,
        };
      }),
      on(actions.addPosGiftItemAction, (state, { payload }) => {
        const referencePosName = Object.values(state.giftListByPosId)[0]
          .pos_entity_name;
        const matchingPriceItemIndex = state.shownGiftListByPosId[
          payload.item.pos_entity_id
        ].gift_list.findIndex((i) => i.price === payload.item.price);
        if (!payload.updated) {
          const errorMessage =
            matchingPriceItemIndex !== -1
              ? t(strings.duplicateItemPrice, {
                  pos_name:
                    state.giftListByPosId[payload.item.pos_entity_id]
                      .pos_entity_name,
                  reference_pos_name: referencePosName,
                })
              : t(strings.itemPriceIncompatible);

          notify.show(errorMessage, "error");
          return state;
        }
        return {
          ...state,
          ...payload.updatedState,
        };
      }),

      on(actions.removePosGiftItemAction, (state, { payload }) => {
        const updatedState = removeGiftItemAcrossPos(
          state,
          payload.pos_entity_id,
          payload
        );

        return {
          ...state,
          ...updatedState,
        };
      }),
      on(actions.editMultiPosGiftListSuccess, (state) => ({
        ...state,
        giftListByPosId: state.shownGiftListByPosId,
      })),
    ],
    initialState
  ),
  getMultiPosGiftListAction
);
