export default {
  title: "title",
  imagePlaceholder: "imagePlaceholder",
  namePlaceholder: "namePlaceholder",
  categoryPlaceholder: "categoryPlaceholder",
  pricePlaceholder: "pricePlaceholder",
  integrationIdPlaceholder: "integrationIdPlaceholder",
  addItem: "addItem",
  uploadImageButtonText: "uploadImageButtonText",
  uploadImageDescription: "uploadImageDescription",
  unsupportedImageType: "unsupportedImageType",
  voucherCapping: "voucherCapping",
  thisIsVoucher: "thisIsVoucher",
  selectVoucher: "selectVoucher",
  addVoucher: "addVoucher",
  selectVoucherDescription: "selectVoucherDescription",
  selectVoucherError: "selectVoucherError",
  onlyVouchersCanBeAdded: "onlyVouchersCanBeAdded",
};
