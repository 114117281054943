import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../selectors";
import { union } from "ts-action";
import {
  editLoyaltyMenuItem,
  editLoyaltyMenuItemFailure,
  editLoyaltyMenuItemSuccess,
} from "../../actions";
import { editLoyaltyMenuItemAction } from "../../../constants";
import { editLoyaltyMenuItemApi } from "../../../axios";
import { notify } from "react-notify-toast";
import { translate } from "../../../helpers/translate";
import strings from "../../../i18n/strings/giftList";

const actionTypes = union({
  editLoyaltyMenuItem,
});
function* editLoyaltyMenuItemSaga({ payload }: typeof actionTypes) {
  const t = translate("giftList");
  try {
    const token = yield select(selectToken);
    const res = yield call(
      editLoyaltyMenuItemApi,
      token,
      typeof payload.is_in_loyalty_program === "number"
        ? {
            id: payload.id,
            is_in_loyalty_program: payload.is_in_loyalty_program,
          }
        : {
            id: payload.id,
            name: payload.name,
            price: payload.price,
            image: payload.image,
            integration_id: payload.integration_id,
          }
    );
    yield put(
      editLoyaltyMenuItemSuccess({
        ...res.data,
        category_id: payload.category_id,
      })
    );
    notify.show(t(strings.loyaltyMapUpdated), "success");
  } catch (error) {
    yield put(
      editLoyaltyMenuItemFailure({ error: error as Error, id: payload.id })
    );
    notify.show(t(strings.failedToAddItems), "error");
  }
}

export function* watchEditLoyaltyMenuItemSaga() {
  yield takeLatest(
    editLoyaltyMenuItemAction.requested,
    editLoyaltyMenuItemSaga
  );
}
