import React from "react";
import { usePOS } from "./usePOS";
import { Tooltip } from "antd";

const styles = {
  wrapper: {
    border: "1px solid #AEAEB2",
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    height: "41px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    padding: "0 20px",
    width: "fit-content",
  },
  text: {
    color: "#1C1C1C",
    fontSize: "15px",
    fontWeight: "600",
  },
};

/**
 * A custom hook that renders a POS name with a tooltip.
 *
 * @param pos_name - Optional string representing the name of the POS account
 * @returns A JSX element containing the POS name in a tooltip wrapper, or null if neither POS nor pos_name exists
 *
 * The hook:
 * - Truncates POS names longer than 10 characters
 * - Displays "Standalone" when no POS name is provided
 * - Wraps the name in a tooltip showing the full name on hover
 * - Returns null when both hasPOS is false and no pos_name is provided
 */
export const useRenderPosName = (pos_name?: string): JSX.Element | null => {
  const { hasPOS } = usePOS(true);
  const renderedPosName = React.useMemo(() => {
    if (!hasPOS && !pos_name) return null;

    return (
      <div style={styles.wrapper}>
        <Tooltip
          color={"#000"}
          overlayInnerStyle={{ fontSize: "14px", fontWeight: "500" }}
          placement="top"
          title={pos_name ?? "Standalone"}
        >
          <span style={styles.text}>
            {pos_name
              ? pos_name.length > 10
                ? `${pos_name.substring(0, 10)}...`
                : pos_name
              : "Standalone"}
          </span>
        </Tooltip>
      </div>
    );
  }, [pos_name, hasPOS]);

  return renderedPosName;
};

export default useRenderPosName;
