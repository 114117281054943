import { TGiftItem, TLoyaltyCategory } from "../../types";

/**
 * Updates the shown categories based on item addition/removal and filtering criteria.
 *
 * @param categoriesById - Object mapping category IDs to their full category information
 * @param currentShownCategoriesById - Current state of visible categories and their items
 * @param item - Gift item being added or removed
 * @param isAdding - Boolean flag indicating if item is being added (true) or removed (false)
 * @param search - Optional search string to filter items by name
 * @param selectedCategoryId - Optional category ID to filter items by category
 *
 * @returns Updated record of shown categories with modified item lists
 *
 */
export const updateShownCategories = (
  categoriesById: Record<string, TLoyaltyCategory>,
  currentShownCategoriesById: Record<string, TLoyaltyCategory>,
  item: TGiftItem,
  isAdding: boolean,
  search: string = "",
  selectedCategoryId: string | null = null
): Record<string, TLoyaltyCategory> => {
  const { category_id: categoryId } = item;
  const category = categoriesById[categoryId];
  const shownCategory = currentShownCategoriesById[categoryId];

  const matchesFilters =
    (search === "" || item.name.toLowerCase().includes(search.toLowerCase())) &&
    (selectedCategoryId === null ||
      selectedCategoryId === "" ||
      categoryId === selectedCategoryId);

  if (!matchesFilters) {
    return currentShownCategoriesById;
  }

  if (isAdding) {
    return {
      ...currentShownCategoriesById,
      [categoryId]: {
        ...(!shownCategory ? category : shownCategory),
        items: shownCategory ? [...shownCategory.items, item] : [item],
      },
    };
  }

  if (!shownCategory) {
    return currentShownCategoriesById;
  }

  const updatedItems = shownCategory.items.filter(
    (element) => element.id !== item.id
  );

  if (updatedItems.length === 0) {
    const { [categoryId]: _, ...remainingCategories } =
      currentShownCategoriesById;
    return remainingCategories;
  }

  return {
    ...currentShownCategoriesById,
    [categoryId]: {
      ...shownCategory,
      items: updatedItems,
    },
  };
};
