import { orderBy } from "lodash";
import { TGiftItem } from "../../types";

/**
 * Reorders a list of gift items by price in ascending order and assigns sequential order numbers.
 * @param {TGiftItem[]} giftList - The array of gift items to be reordered
 * @returns {TGiftItem[]} A new array of gift items sorted by price with updated order numbers
 */
export const reorderGiftList = (giftList: TGiftItem[]): TGiftItem[] =>
  orderBy(giftList, ["price"], ["asc"]).map((item, index) => ({
    ...item,
    order: index,
  }));
