import { on, reducer } from "ts-action";
import {
  LoadingStatus,
  withLoadingReducer,
} from "../../../Components/Pickup/redux-store/reducers/withLoadingState";
import { getLoyaltySpecialItemsAction } from "../../../constants";
import * as actions from "../../actions";
import { getUniqueItems } from "../../../helpers/getUniqueItems";
import { sortBy } from "lodash";
import { LoyaltySpecialItemsState, TLoyaltyItem } from "../../../types";
import { updateLoyaltyMenuItem } from "../../../helpers/gift-list/updateLoyaltyMenuItem";

export default withLoadingReducer<LoyaltySpecialItemsState>(
  reducer<LoyaltySpecialItemsState>(
    [
      on(actions.editLoyaltyMenuItemSuccess, (state, { payload, type }) =>
        updateLoyaltyMenuItem(state, payload, type)
      ),
      on(actions.editLoyaltyMenuItemFailure, (state, { payload, type }) =>
        updateLoyaltyMenuItem(state, payload.id, type)
      ),
      on(actions.editLoyaltyMenuItem, (state, { payload, type }) =>
        updateLoyaltyMenuItem(state, payload.id, type)
      ),
      on(actions.getLoyaltyMenuSuccess, (state, { payload }) => {
        const giftItems = getUniqueItems([
          ...state.giftItems,
          ...payload.menu
            .flatMap((cat) =>
              cat.items.map((item) => ({ ...item, category_id: cat.id }))
            )
            .filter((item) => item.is_in_loyalty_program),
        ]);
        return {
          ...state,
          giftItems: sortBy(giftItems, ["price"]),
        };
      }),
      on(actions.getLoyaltySpecialItemsSuccess, (state, { payload }) => ({
        ...state,
        giftItems: sortBy(getUniqueItems([...state.giftItems, ...payload]), [
          "price",
        ]),
      })),
      on(actions.deleteLoyaltySpecialItem, (state) => ({
        ...state,
        status: LoadingStatus.loading,
      })),
      on(actions.deleteLoyaltySpecialItemFailure, (state) => ({
        ...state,
        status: LoadingStatus.failed,
      })),
      on(actions.deleteLoyaltySpecialItemSuccess, (state, { payload }) => ({
        ...state,
        giftItems: state.giftItems.filter((item) => item.id !== payload),
        status: LoadingStatus.success,
      })),
      on(actions.editLoyaltySpecialItem, (state) => ({
        ...state,
        status: LoadingStatus.loading,
      })),
      on(actions.editLoyaltySpecialItemFailure, (state) => ({
        ...state,
        status: LoadingStatus.failed,
      })),
      on(actions.editLoyaltySpecialItemSuccess, (state, { payload }) => ({
        ...state,
        giftItems: state.giftItems.map((item) => {
          if (item.id === payload.id) {
            return {
              ...item,
              ...payload,
            };
          }
          return item;
        }),
        status: LoadingStatus.success,
      })),
      on(actions.addLoyaltySpecialItem, (state) => ({
        ...state,
        status: LoadingStatus.loading,
      })),
      on(actions.addLoyaltySpecialItemSuccess, (state, { payload }) => ({
        ...state,
        giftItems: sortBy([...state.giftItems, payload], ["price"]),
        status: LoadingStatus.success,
      })),
      on(actions.addLoyaltySpecialItemFailure, (state) => ({
        ...state,
        status: LoadingStatus.failed,
      })),
    ],
    {
      giftItems: [],
      status: undefined,
    }
  ),
  getLoyaltySpecialItemsAction
);
