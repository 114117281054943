import menu from "./menu";
export default {
  addFromMenu: "أضف من القائمة",
  customGift: "أضف جائزة خاصة",
  giftName: "اسم الجائزة",
  price: "السعر",
  atLeast: "(قم بإضافة 7 منتجات على الأقل)",
  giftList: "قائمة الجوائز",
  giftItems: "عدد الجوائز",
  progressAutoSaved: menu.progressAutoSaved,
  markComplete: menu.markComplete,
  addSpecialItemSuccess: 'تم إضافة "{{name}}" إلى قائمة هداياك بنجاح!',
  addSpecialItemFailure: 'فشل إضافة "{{name}}" إلى قائمة هداياك',
  itemHasNoImageError:
    "أي منتج في قائمة الهدايا يجب أن يكون به صورة، رجاءاً قم بتعديل هذا المنتج في صفحة قائمة المنتجات",
  giftMapIncompatible:
    "قائمة الهدايا الخاصة بـ “{{pos_name}}” غير متوافقة مع قوائم الهدايا الأخرى. قم بتعديلها لتوافق قائمة الهدايا الخاصة ب “{{reference_pos_name}}”",
  failedToAddItems:
    "حدث خطأ في الخادم أو الاتصال، يرجى المحاولة مرة أخرى لاحقاً.",
  areYouSureYouWantToDeleteThisItem: "متأكد أنك تريد حذف هذه الهدية؟",
  deleteGiftItemDescription:
    "بحذف هذه الهدية من “{{pos_name}}”، سيتم حذف كل الهدايا المشابهة في نقاط البيع الأخرى أيضا. هل أنت متأكد؟",
  cancel: "إلغاء",
  delete: "حذف",
  duplicateItemPrice:
    "توجد هدية بنفس السعر في “{{pos_name}}”، يمكنك حذف الهدية الموجودة أولا أو أضف هدية أخرى بنفس السعر في “{{reference_pos_name}}” قبل المتابعة.",
  saveChanges: "حفظ التغييرات",
  loyaltyMapUpdated: "تم تحديث قائمة الولاء",
  itemPriceIncompatible: "السعر غير متوافق",
};
